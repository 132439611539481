<template>
    <div class="container">
      <van-sticky>
       <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
      </van-nav-bar>
    </van-sticky>
      <!-- <van-swipe :autoplay="3000" style="height:120px;width:100%">
        <van-swipe-item v-for="(item, index) in images" :key="index">
          <img
            :src="item.imageUrl"
            style="width:100%;height:100%"
            @click="$router.push(item.urlPath)"
          />
        </van-swipe-item>
      </van-swipe> -->
  
      <van-tabs v-model="activeName" sticky >
        <van-tab title="评论" name="b" :badge="getMyComment().messageCount">
          <div class="tabcontent">
            <Mycomment></Mycomment>
          </div>
        </van-tab>
        <van-tab title="点赞" name="a" :badge="getMyLike().messageCount">
          <div class="tabcontent">
            <Mylike></Mylike>
          </div>
        </van-tab>
  
      
      </van-tabs>
    </div>
  </template>
  
  <script>
  import Mycomment from "./mycomment";
  import Mylike from "./components/mylike.vue";
  
import {  mapGetters } from "vuex";
  //const key = "zn-history";
  export default {
    name: "mycommentlike",
    data() {
      return {
        activeName: "b",
        title:"收到的评论点赞"
      };
    },
    components: {
        Mylike,
        Mycomment,
    },
    activated() {},
    created() {
   
    },
    updated() {
      //this.showQW();
    },
    watch: {
    },
  
    methods: {
      ...mapGetters([
      "getMyComment",
      "getMyLike"
      ,
    ]),
      
    },
  };
  </script>
  
  <style lang="less" scoped>
  .w100 {
    /deep/ img {
      width: 100%;
      max-height: 200px;
    }
  }
  .zntitle {
    height: 30px;
  }
  .container {
    height: 100%;
    //overflow-y: auto;
    //box-sizing: border-box;
  
    /deep/.van-tabs {
      height: 100%;
    }
    /deep/.van-tabs__content {
      height: 100%;
    }
  }
  .van-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;
    /deep/ .van-tabs__wrap {
      height: 36px;
      padding-right: 36px;
      .van-tab {
        line-height: 36px;
      }
      .van-tabs__line {
        background-color: #3296fa;
        height: 2px;
      }
    }
    /deep/ .van-info
    {
      top: 17px;
    right: -15px;
    }
    /deep/ .van-tabs__content {
      flex: 1;
      overflow: hidden;
    }
    /deep/ .van-tab__pane {
      height: 100%;
      .scroll-wrapper {
        height: 100%;
        overflow-y: auto;
      }
    }
  }
  
  .tabcontent {
    height: 100%;
    margin-bottom: 138px;
  }
  
  .acontent {
    max-height: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  
    /deep/ ol {
      list-style: decimal !important;
      margin-left: 25px;
    }
    /deep/ ol li {
      list-style-position: outside;
    }
    /deep/ img {
      max-width: 33% !important;
      display: block;
      border: 0;
    }
    /deep/ video {
      max-width: 100% !important;
    }
  }
  .detail {
    padding: 46px 10px 44px;
    .title {
      font-size: 18px;
      line-height: 2;
    }
    .zan {
      text-align: center;
      padding: 10px 0;
      .active {
        border-color: red;
        color: red;
      }
    }
    .author {
      padding: 10px 0;
      display: flex;
      position: sticky;
      background-color: #fff;
      top: 46px;
      z-index: 2;
      .text {
        flex: 1;
        padding-left: 10px;
        line-height: 1.5;
        .name {
          font-size: 14px;
          margin: 0;
        }
        .time {
          margin: 0;
          font-size: 12px;
          color: #999;
        }
      }
    }
    .content {
      padding: 20px 0;
      overflow: hidden;
      white-space: pre-wrap;
      word-break: break-all;
      /deep/ img {
        max-width: 100%;
        background: #f9f9f9;
      }
      /deep/ code {
        white-space: pre-wrap;
      }
    }
  }
  </style>
  